@import '../../../../scss/theme-bootstrap';

.product-quickshop-wrapper {
  position: relative;
  display: inline-block;
}

.product-quickshop {
  margin: 0 auto;
  width: 100%;
}

.product-quickshop__product {
  position: relative;
  overflow: hidden;
}

.product-quickshop__misc-flag {
  font-family: $font-inter-regular;
  text-align: center;
  font-size: 12px;
  color: $color-orange;
}

.product-quickshop__image {
  width: 40.11976%;
  float: #{$ldirection};
  padding: 40px 0;
  .product-full__image-carousel {
    .carousel-controls {
      display: none;
    }
  }
  .product-full__image-thumbnails {
    display: none;
  }
  .product-full__carousel__slides {
    height: auto;
  }
  .product-full__carousel__thumbnail {
    float: #{$ldirection};
    width: 33%;
    cursor: pointer;
    &:nth-child(3n + 1) {
      clear: #{$ldirection};
    }
    &.active {
      border: 1px solid $color-border;
    }
  }
}

.product-quickshop__detail {
  width: 59.88024%;
  float: #{$rdirection};
  padding: 80px 40px;
  font-family: $font--sans;
  .product-sku-price {
    font-weight: bold;
  }
}
body {
  &.page-products,
  .section-products {
    .product-quickshop {
      &__image {
        width: 35%;
      }
      .product-quickshop__detail {
        width: 65%;
        padding: 80px 20px 80px 5px;
      }
    }
  }
}
.product-quickshop__name {
  @include product-name;
}

.product-quickshop__subline {
  @include product-subline;
}

.product-quickshop__sub-row {
  padding: 15px 0 0 0;
  .product-sku-price {
    height: 1em;
  }
  .product-offer {
    padding: 20px 0;
  }
  .product-offer__text {
    font-size: 14px;
    line-height: 21px;
  }
  select,
  a.selectBox {
    min-width: 100%;
    max-width: 100%;
    height: 40px;
    line-height: 40px;
    .selectBox-label.sku-menu__option {
      padding: 0 5px;
      @include breakpoint($large-up) {
        font-size: 10px;
      }
    }
  }
  .product-quickshop {
    &__details-link {
      .link {
        text-decoration: underline;
        color: $color-orange;
      }
    }
  }
}

.product-quickshop__sku {
  .product-sku-price {
    @include font-inter-medium;
    @include tracking(20);
    font-size: 18px;
  }
  .sku-menu__container {
    padding: 15px 0 0 0;
  }
}

.product-quickshop__add-to-bag-container {
  @include clearfix;
  padding: 15px 0 0 0;
}

.product-quickshop__qty {
  float: #{$ldirection};
  width: 25%;
}

.product-quickshop__buttons {
  float: #{$rdirection};
  width: 70%;
  .button {
    max-width: 100%;
    @include button--dark;
  }
}

.product-quickshop__details-link {
  position: absolute;
  bottom: 40px;
  #{$rdirection}: 40px;
  height: 1.25em;
  .link {
    font-size: 14px;
    color: $color-red;
    text-decoration: none;
  }
  &:hover {
    border-bottom: $color-red solid 1px;
  }
}

.product-quickshop__close {
  .qs-overlay-cbox & {
    display: none;
  }
}
